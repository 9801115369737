(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
import { css } from "styled-components";
import { ThemeTypeEnum } from "~/types/enums";
export const genericColorCombination = (theme, color) => {
  if (Array.isArray(color)) {
    const [background, textColor, focus] = color;
    return {
      background,
      color: textColor,
      focus: focus || background
    };
  }
  const isDark = theme.type === ThemeTypeEnum.DARK;
  const {
    primary,
    secondary,
    grey,
    text,
    background,
    info,
    danger,
    warning,
    success,
    note,
    ai
  } = theme.colors;
  switch (color) {
    case "primary":
      return {
        background: primary.main,
        color: primary.text,
        focus: primary.main
      };
    case "secondary":
      return {
        background: secondary.main,
        color: secondary.text,
        focus: secondary.main
      };
    case "disabled":
      return {
        background: primary.disabled,
        color: text.secondary,
        focus: primary.disabled
      };
    case "grey":
      return {
        background: grey.main,
        color: grey.text,
        focus: text.primary
      };
    case "primaryGrey":
      return {
        background: grey.main,
        color: primary.main,
        focus: primary.main
      };
    case "secondaryGrey":
      return {
        background: grey.main,
        color: secondary.main,
        focus: secondary.main
      };
    case "whiteGrey":
      return {
        background: grey.main,
        color: background.primary,
        focus: grey.main
      };
    case "infoGrey":
      return {
        background: grey.main,
        color: info.main,
        focus: info.main
      };
    case "warningGrey":
      return {
        background: grey.main,
        color: warning.main,
        focus: warning.main
      };
    case "dangerGrey":
      return {
        background: grey.main,
        color: danger.main,
        focus: danger.main
      };
    case "successGrey":
      return {
        background: grey.main,
        color: success.main,
        focus: success.main
      };
    case "white":
      return {
        background: background.primary,
        color: text.primary,
        focus: grey.main
      };
    case "primaryWhite":
      return {
        background: background.primary,
        color: primary.main,
        focus: grey.main
      };
    case "secondaryWhite":
      return {
        background: background.primary,
        color: secondary.main,
        focus: grey.main
      };
    case "infoWhite":
      return {
        background: background.primary,
        color: info.main,
        focus: info.main
      };
    case "warningWhite":
      return {
        background: background.primary,
        color: warning.main,
        focus: warning.main
      };
    case "dangerWhite":
      return {
        background: background.primary,
        color: danger.main,
        focus: danger.main
      };
    case "successWhite":
      return {
        background: background.primary,
        color: success.main,
        focus: success.main
      };
    case "transparent":
      return {
        background: "transparent",
        color: text.primary,
        focus: text.primary
      };
    case "greyTransparent":
      return {
        background: "transparent",
        color: text.secondary,
        focus: text.secondary
      };
    case "primaryTransparent":
      return {
        background: "transparent",
        color: primary.main,
        focus: primary.main
      };
    case "secondaryTransparent":
      return {
        background: "transparent",
        color: secondary.main,
        focus: secondary.main
      };
    case "infoTransparent":
      return {
        background: "transparent",
        color: info.main,
        focus: info.main
      };
    case "dangerTransparent":
      return {
        background: "transparent",
        color: danger.main,
        focus: danger.main
      };
    case "warningTransparent":
      return {
        background: "transparent",
        color: warning.main,
        focus: warning.main
      };
    case "successTransparent":
      return {
        background: "transparent",
        color: success.main,
        focus: success.main
      };
    case "translucentWhite":
      return {
        background: `rgba(${isDark ? "255, 255, 255, 0.2" : "0, 0, 0, 0.05"})`,
        color: text.primary,
        focus: text.primary
      };
    case "info":
      return {
        background: info.main,
        color: background.primary,
        focus: info.main
      };
    case "danger":
      return {
        background: danger.main,
        color: background.primary,
        focus: danger.main
      };
    case "warning":
      return {
        background: warning.main,
        color: background.primary,
        focus: warning.main
      };
    case "success":
      return {
        background: success.main,
        color: background.primary,
        focus: success.main
      };
    case "note":
      return {
        background: note.main,
        color: note.text,
        focus: note.main
      };
    case "noteLight":
      return {
        background: note.secondary,
        color: note.text,
        focus: note.secondary
      };
    case "ai":
      return {
        backgroundImage: ai.background,
        color: ai.text,
        focus: ai.main
      };
    case "inherit":
      return {
        background: "inherit",
        color: "inherit",
        focus: "inherit"
      };
    case "background":
      return {
        background: background.primary,
        color: text.primary,
        focus: text.primary
      };
    default:
      return {
        background: color,
        color: background.primary,
        focus: color
      };
  }
};
export const genericColor = (theme, color) => {
  const {
    primary,
    grey,
    secondary,
    background,
    text,
    info,
    danger,
    warning,
    success,
    note,
    ai
  } = theme.colors;
  switch (color) {
    case "text":
      return text.primary;
    case "textSecondary":
      return text.secondary;
    case "primary":
      return primary.main;
    case "secondary":
      return secondary.main;
    case "disabled":
      return text.disabled;
    case "grey":
      return grey.main;
    case "white":
      return background.primary;
    case "info":
      return info.main;
    case "danger":
      return danger.main;
    case "warning":
      return warning.main;
    case "success":
      return success.main;
    case "note":
      return note.main;
    case "ai":
      return ai.main;
    case "background":
      return background.primary;
    case "backgroundSecondary":
      return background.secondary;
    case "backgroundDark":
      return background.dark;
    case "inherit":
      return "inherit";
    default:
      return color;
  }
};
export const resolveSpacingProp = (theme, values, step) => {
  if (!values) {
    return "";
  }
  return Object.entries(values).map(_ref => {
    let [key, value] = _ref;
    const em = value / (theme.typography.fontSize / step);
    const props = {
      m: `margin: ${em}rem;`,
      mt: `margin-top: ${em}rem;`,
      ml: `margin-left: ${em}rem;`,
      mr: `margin-right: ${em}rem;`,
      mb: `margin-bottom: ${em}rem;`,
      mx: `margin-left: ${em}rem; margin-right: ${em}rem;`,
      my: `margin-top: ${em}rem; margin-bottom: ${em}rem;`,
      p: `padding: ${em}rem;`,
      pt: `padding-top: ${em}rem;`,
      pl: `padding-left: ${em}rem;`,
      pr: `padding-right: ${em}rem;`,
      pb: `padding-bottom: ${em}rem;`,
      px: `padding-left: ${em}rem; padding-right: ${em}rem;`,
      py: `padding-top: ${em}rem; padding-bottom: ${em}rem;`
    };
    return props[key];
  }).join("");
};
export const resolveOutlineProp = (theme, active, color) => {
  let shadow = css`
    box-shadow: ${theme.colors.background.primary} 0 0 0 2px, ${color || "#5F75EE"} 0 0 0 4px;
  `;
  if (active === undefined) {
    shadow = css`
      &:focus-within {
        ${shadow}
      }
    `;
  } else {
    shadow = active ? shadow : css``;
  }
  return css`
    outline: none;
    transition: box-shadow 200ms ease-in;
    box-shadow: ${theme.colors.background.primary} 0 0 0 0, transparent 0 0 0 1px;

    ${shadow}
  `;
};
export const resolveAnimations = {
  pulse: (id, theme, color) => css`
    animation: ${`${id}-${color}`} 2s infinite;

    @keyframes ${`${id}-${color}`} {
      0% {
        box-shadow: 0 0 0 0 ${genericColorCombination(theme, color).background}BF;
      }

      70% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
      }

      100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      }
    }
  `
};
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(genericColorCombination, "genericColorCombination", "/codebuild/output/src4245856563/src/src/lib/resolvers.ts");
  reactHotLoader.register(genericColor, "genericColor", "/codebuild/output/src4245856563/src/src/lib/resolvers.ts");
  reactHotLoader.register(resolveSpacingProp, "resolveSpacingProp", "/codebuild/output/src4245856563/src/src/lib/resolvers.ts");
  reactHotLoader.register(resolveOutlineProp, "resolveOutlineProp", "/codebuild/output/src4245856563/src/src/lib/resolvers.ts");
  reactHotLoader.register(resolveAnimations, "resolveAnimations", "/codebuild/output/src4245856563/src/src/lib/resolvers.ts");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();